const ones = [
  "",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
];
const tens = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];
const teens = [
  "ten",
  "eleven",
  "twelve",
  "thirteen",
  "fourteen",
  "fifteen",
  "sixteen",
  "seventeen",
  "eighteen",
  "nineteen",
];

export function convertToWords(amount) {
  const [rials, baisa] = amount.toString().split(".");
  let result = "";

  function convert(n) {
    if (n < 10) return ones[n];
    if (n < 20) return teens[n - 10];
    if (n < 100)
      return (
        tens[Math.floor(n / 10)] + (n % 10 !== 0 ? "-" + ones[n % 10] : "")
      );
    if (n < 1000)
      return (
        ones[Math.floor(n / 100)] +
        " hundred" +
        (n % 100 !== 0 ? " and " + convert(n % 100) : "")
      );
    if (n < 1000000)
      return (
        convert(Math.floor(n / 1000)) +
        " thousand" +
        (n % 1000 !== 0 ? " " + convert(n % 1000) : "")
      );
    if (n < 1000000000)
      return (
        convert(Math.floor(n / 1000000)) +
        " million" +
        (n % 1000000 !== 0 ? " " + convert(n % 1000000) : "")
      );
    return (
      convert(Math.floor(n / 1000000000)) +
      " billion" +
      (n % 1000000000 !== 0 ? " " + convert(n % 1000000000) : "")
    );
  }

  if (parseInt(rials) === 0) {
    result = "zero";
  } else {
    result = convert(parseInt(rials));
    result += " rial" + (parseInt(rials) === 1 ? "" : "s");
  }

  if (baisa) {
    const baisaValue = parseInt(baisa.padEnd(2, "0").slice(0, 2));
    if (baisaValue > 0) {
      result += " and " + convert(baisaValue) + " baisa";
    }
  }

  return result.trim();
}
