import React from "react";
import i18n from "../../../i18n";
import swal from "sweetalert";
import { createWallet } from "../../../models/wallet";
import { convertToWords } from "../../utils/formatter";

const tableHeaderStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  padding: "0.5rem 0.5rem",
  border: "1px solid #efefef",
  fontSize: "0.8rem",
};
const tableCellStyle = {
  border: "1px solid #efefef",
  fontSize: "0.8rem",
};
const tableFooterStyle = {
  ...tableCellStyle,
  fontWeight: "bold",
  backgroundColor: "#f8f9fa",
  border: "1px solid rgb(208 208 208)",
  textAlign: "center",
};
const tableLastFooterStyle = {
  ...tableCellStyle,
  fontWeight: "bold",
  backgroundColor: "#f8f9fa",
  border: "1px solid #dedede",
};
const statuses = {
  A: i18n.t("approved"),
  R: i18n.t("rejected"),
};
class AuctionTable extends React.Component {
  constructor() {
    super();
    this.state = {
      show_fund_screen: false,
      add: {},
      errors: {},
      selected_group: null,
    };
    this.handleAddChange = this.handleAddChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  handleAddChange(event) {
    let { add } = this.state;
    add[event.target.name] = event.target.value;
    this.setState({ add });
  }

  handleSelectAll = (event) => {
    const { auctions, onSelectAll } = this.props;
    const { checked } = event.target;
    onSelectAll(
      checked
        ? auctions.map((auction) =>
            !auction.approve_status ? auction.id : null
          )
        : []
    );
  };

  handleSelectRow = (event, id) => {
    const { checked } = event.target;
    const { selectedRows, onSelectRow } = this.props;

    if (checked) {
      onSelectRow([...selectedRows, id]);
    } else {
      onSelectRow(selectedRows.filter((rowId) => rowId !== id));
    }
  };

  async handleSubmit(event) {
    event.preventDefault();
    if (this.validate()) {
      try {
        const response = await createWallet(this.state.add);
        if (response.success) {
          swal(i18n.t("success"), "Fund added successfully!!", "success", {
            buttons: false,
            timer: 2000,
          })
            .then((value) => {
              let { selected_group } = this.state;
              this.resetForm();
              this.props.onTrigger(selected_group);
              this.setState({ open_popup: false });
            })
            .catch((response) => {
              swal("Failed", response.message, "error");
            });
        } else {
          let error_message = response.message;
          let resObject = response.data;
          for (const key in resObject) {
            if (Object.hasOwnProperty.call(resObject, key)) {
              let element = resObject[key];
              error_message += "\n" + element;
            }
          }
          swal("Failed", error_message, "error");
        }
      } catch (err) {}
    }
  }

  validate() {
    let { add } = this.state;
    let errors = {};
    let isValid = true;
    if (!add["user"]) {
      isValid = false;
      errors["user"] = "Please select a user.";
    }
    if (!add["credit"]) {
      isValid = false;
      errors["credit"] = "Please enter an amount.";
    }
    this.setState({
      errors: errors,
    });
    return isValid;
  }

  resetForm() {
    let { add } = this.state;
    add["name"] = "";
    add["mobile"] = "";
    add["email"] = "";
    add["username"] = "";
    add["country_code"] = "+968";
    add["password"] = "";
    add["role"] = "";
    this.setState({ add: add });
  }

  calculateTotalServiceCharge() {
    const { auctions, selectedRows } = this.props;
    return auctions
      .filter((item) => selectedRows.includes(item.id))
      .reduce((total, item) => {
        const amount = parseFloat(item?.winner?.service_charge) || 0;
        return total + amount;
      }, 0)
      .toFixed(2);
  }

  calculateTotalVatServiceCharge() {
    const { auctions, selectedRows } = this.props;
    return auctions
      .filter((item) => selectedRows.includes(item.id))
      .reduce((total, item) => {
        const amount = parseFloat(item?.winner?.vat_service_charge) || 0;
        return total + amount;
      }, 0)
      .toFixed(2);
  }

  calculateItemVatAmount() {
    const { auctions, selectedRows } = this.props;
    return auctions
      .filter((item) => selectedRows.includes(item.id))
      .reduce((total, item) => {
        const amount = parseFloat(item?.winner?.item_amount_vat) || 0;
        return total + amount;
      }, 0)
      .toFixed(2);
  }

  calculateTotalAmount() {
    const { auctions, selectedRows } = this.props;
    return auctions
      .filter((item) => selectedRows.includes(item.id))
      .reduce((total, item) => {
        const amount = parseFloat(item?.winner?.total_amount) || 0;
        return total + amount;
      }, 0)
      .toFixed(2);
  }

  render() {
    const { auctions, selectedRows, group } = this.props;
    const selectAll = selectedRows.length === auctions.length;
    const totalServiceChrg = this.calculateTotalServiceCharge();
    const totalVatServiceChrg = this.calculateTotalVatServiceCharge();
    const totalItemVat = this.calculateItemVatAmount();
    const totalAmount = this.calculateTotalAmount();

    return (
      <>
        <div
          className="rounded-3"
          style={{
            maxHeight: "250px",
            overflow: "scroll",
            padding: "1rem",
          }}
        >
          <table className="table table-stripped table-sm">
            <thead>
              <tr>
                <th style={tableHeaderStyle}>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={this.handleSelectAll}
                  />
                </th>
                <th style={tableHeaderStyle}>{i18n.t("sl_no")}</th>
                <th style={tableHeaderStyle}>{i18n.t("ID")}</th>
                <th style={tableHeaderStyle}>{i18n.t("auction")}</th>
                <th style={tableHeaderStyle}>{i18n.t("client")}</th>
                <th style={tableHeaderStyle}>{i18n.t("winner")}</th>
                <th style={tableHeaderStyle}>{i18n.t("latest_bid_amount")}</th>
                <th style={tableHeaderStyle}>
                  {i18n.t("wallet")}-{i18n.t("balance")}
                </th>
                <th style={tableHeaderStyle}>{i18n.t("service_charge")}</th>
                <th style={tableHeaderStyle}>{i18n.t("vat_service_charge")}</th>
                <th style={tableHeaderStyle}>{i18n.t("item_amount_vat")}</th>
                <th style={tableHeaderStyle}>
                  {i18n.t("total_amount")}-{i18n.t("bidder")}
                </th>
                <th style={tableHeaderStyle}>{i18n.t("status")}</th>
              </tr>
            </thead>
            <tbody>
              {auctions.map((item, i) => (
                <tr key={item.id}>
                  <td style={tableCellStyle}>
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(item.id)}
                      onChange={(e) => this.handleSelectRow(e, item.id)}
                      disabled={item.approve_status}
                    />
                  </td>
                  <td style={tableCellStyle}>{i + 1}</td>
                  <td style={tableCellStyle}>{item.auction_number}</td>
                  <td style={tableCellStyle}>{item.title}</td>
                  <td style={tableCellStyle}>
                    {item?.organizationDetails?.organization_name}
                  </td>
                  <td style={tableCellStyle}>
                    {item?.winner?.name ? item?.winner?.name : "N/A"}
                  </td>
                  <td style={tableCellStyle}>
                    {item?.winner?.user_id ? item?.winner?.bid_amount : "N/A"}
                  </td>
                  <td style={tableCellStyle}>
                    <div>{item?.winner?.wallet_amount}</div>
                  </td>
                  <td style={tableCellStyle}>{item?.winner?.service_charge}</td>
                  <td style={tableCellStyle}>
                    {item?.winner?.vat_service_charge}
                  </td>
                  <td style={tableCellStyle}>
                    {item?.winner?.item_amount_vat}
                  </td>
                  <td style={tableCellStyle}>{item?.winner?.total_amount}</td>
                  <td style={tableCellStyle}>
                    {item?.approve_status
                      ? statuses[item?.approve_status]
                      : i18n.t("pending")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr />
        </div>

        {selectedRows.length > 0 && (
          <table className="table table-stripped table-sm">
            <tfoot>
              <tr>
                <td style={tableFooterStyle} align="right">
                  {i18n.t("total_service_charge")}:{totalServiceChrg}
                </td>
                <td style={tableFooterStyle}>
                  {i18n.t("total_vat_service_amount")}:{totalVatServiceChrg}
                </td>
                <td style={tableFooterStyle}>
                  {i18n.t("total_vat_amount")}:{totalItemVat}
                </td>
                <td style={tableFooterStyle}>
                  {i18n.t("total_amount")}:{totalAmount}
                </td>
              </tr>
              <tr>
                <td colSpan="4" style={tableLastFooterStyle} align="center">
                  {convertToWords(totalAmount).toUpperCase()}
                </td>
              </tr>
            </tfoot>
          </table>
        )}
      </>
    );
  }
}

export default AuctionTable;
