import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SocialSignin } from "./socialSignin";
import swal from "sweetalert";
import { loginUser } from "../../models/login";
import i18n from "../../i18n";
import { baseUrl } from "../../constants/global";

export default function Login() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [passwordType, setPasswordType] = useState("password");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await loginUser({
      username,
      password,
    });

    if ("token" in response.data) {
      swal(i18n.t("success"), i18n.t("login_message"), "success", {
        buttons: false,
        timer: 2000,
      })
        .then((value) => {
          localStorage.setItem("is_logged_in", true);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("mzad_user_info", JSON.stringify(response.data));
          if (response.data.role === "A") {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/";
          }
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    } else {
      swal("Failed", response.message, "error");
    }
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <React.Fragment>
      <div className="page-container">
        <div className="blue-menu-bg py-4"></div>
        <div className="content-bg">
          <div className="container py-5">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-lg-6 col-lg-3">
                <div className="w-85 mx-auto">
                  <div className="blue-container">
                    <div className="mx-3 mb-3 mobile_size">
                      <img src="./assets/images/Vector_login.png" alt="" />
                      <span className="custom-text5 ms-1 text-white">
                        {i18n.t("login")}
                      </span>
                    </div>

                    <div className="container m-auto p-0">
                      <div className="row">
                        <div className="bg-white user-brd rounded-4 px-0">
                          <div className="d-flex gap-5">
                            <div className="col ps-4 pt-4 pb-4 px-4">
                              <form
                                id="login"
                                className=""
                                onSubmit={handleSubmit}
                              >
                                <div className="col-md-12 col-md-6 col-md-3">
                                  <div>
                                    <label for="username">
                                      {i18n.t("username_or_email")}
                                    </label>
                                  </div>
                                  <div className="mt-1">
                                    <input
                                      className="form-control custom-input rounded-pill"
                                      type="text"
                                      id="username"
                                      name="username"
                                      placeholder={i18n.t("username_or_email")}
                                      onChange={(e) =>
                                        setUserName(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 col-md-6 col-md-3">
                                  <div className="mt-1">
                                    <label for="password">
                                      {i18n.t("password")}
                                    </label>
                                  </div>
                                  <div className="mt-1 wrapper">
                                    <input
                                      className="form-control custom-input rounded-pill"
                                      type={passwordType}
                                      id="password"
                                      name="password"
                                      placeholder={i18n.t(
                                        "please_enter_password"
                                      )}
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                    />

                                    {passwordType === "password" ? (
                                      <i
                                        className="fa fa-eye-slash password-eye"
                                        onClick={togglePassword}
                                      ></i>
                                    ) : (
                                      <i
                                        className="fa-solid fa-eye password-eye"
                                        onClick={togglePassword}
                                      ></i>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-12 col-md-6 col-md-3">
                                  <div className="d-flex flex-row-reverse mt-1">
                                    <Link
                                      to="/forgotPassword"
                                      className="custom_forgetpassword"
                                    >
                                      {i18n.t("forget_password")}
                                    </Link>
                                  </div>
                                </div>
                                <div className="col-md-12 col-md-6 col-md-3">
                                  <div className="text-center mt-4">
                                    <button
                                      type="submit"
                                      className="btn custom_btn-color rounded-pill form-control"
                                    >
                                      <span className="custom-text">
                                        {i18n.t("login")}
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <div className="col-md-12 col-md-6 col-md-3">
                                  <div className="text-center d-flex justify-content-between align-items-center mt-2">
                                    <span className="me-5"></span>
                                    <p className="text-center ms-4">
                                      <Link
                                        to="/login_otp"
                                        className="custom-text2"
                                      >
                                        {i18n.t("login_using_otp")}
                                      </Link>
                                    </p>
                                    <p className="">
                                      <a
                                        className="custom-text3 d-flex"
                                        target="_new"
                                        href={baseUrl + "/guide/login.pdf"}
                                      >
                                        <img
                                          height="15.157"
                                          width="15.157"
                                          src="./assets/images/uiw_file-pdf.png"
                                          alt=""
                                        />
                                        {i18n.t("user_guide")}
                                      </a>
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-12 col-md-6 col-md-3">
                                  <div className="text-center">
                                    <p>
                                      <a href={true} className="custom-text4">
                                        {i18n.t("donthaveaccount")}
                                      </a>
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-12 col-md-6 col-md-3">
                                  <div className="text-center custom-outline-button">
                                    <Link
                                      to="/comp-registration"
                                      className="btn rounded-pill custom-outline-button form-control"
                                    >
                                      {i18n.t("register_bidder")}
                                    </Link>
                                  </div>
                                </div>
                                <div className="col-md-12 col-md-6 col-md-3">
                                  <div className="text-center custom-outline-button mt-2">
                                    <Link
                                      to="/client-registration"
                                      className="btn rounded-pill custom-outline-button form-control"
                                    >
                                      {i18n.t("register_client")}
                                    </Link>
                                  </div>
                                </div>
                                <div className="col-md-12 col-md-6 col-md-3">
                                  <div className="horizontal-line">
                                    <span className="line-text">
                                      {i18n.t("or_continue")}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-12 col-md-6 col-md-3">
                                  <div className="text-center">
                                    <SocialSignin />
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div className="col image-side mobile_hide">
                              <img
                                className="img-fluid"
                                src="./assets/images/1696936701627 1.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
  //render() {
  // return (
  //   {/*
  //   <div>
  //     <section>
  //       <div
  //         className="sptb-1 cover-image"
  //         data-image-src="../assets/images/banners/banner2.jpg"
  //       >
  //         <div className="header-text mb-0 pb-3">
  //           <div className="container">
  //             <div className="text-center text-white">
  //               <h1 className="wft">{i18n.t("login")}</h1>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </section>

  //     <section className="sptb background-custom">
  //       <div className="container customerpage">
  //         <div className="row">
  //           <div className="single-page">
  //             <div className="col-lg-5 col-xl-5 col-md-6 d-block mx-auto">
  //               <div className="wrapper wrapper2">
  //                 <form
  //                   id="login"
  //                   className="card-body"
  //                   onSubmit={handleSubmit}
  //                 >
  //                   <div className="mail">
  //                     <input
  //                       type="text"
  //                       name="username"
  //                       onChange={(e) => setUserName(e.target.value)}
  //                     />
  //                     <label>{i18n.t("username_or_email")}</label>
  //                   </div>
  //                   <div className="passwd">
  //                     <input
  //                       type={passwordType}
  //                       name="password"
  //                       onChange={(e) => setPassword(e.target.value)}
  //                     />
  //                     {passwordType === "password" ? (
  //                       <i
  //                         className="fa fa-eye-slash password-eye"
  //                         onClick={togglePassword}
  //                       ></i>
  //                     ) : (
  //                       <i
  //                         className="fa fa-eye password-eye"
  //                         onClick={togglePassword}
  //                       ></i>
  //                     )}
  //                     <label>{i18n.t("password")}</label>
  //                   </div>
  //                   <div>
  //                     <a
  //                       className="btn btn-outline-info"
  //                       target="_new"
  //                       href={baseUrl + "/guide/login.pdf"}
  //                       style={{
  //                         color: "#27535f",
  //                         fontWeight: "bold",
  //                         padding: "0.5rem 3rem",
  //                       }}
  //                     >
  //                       {i18n.t("user_guide")}
  //                     </a>
  //                   </div>
  //                   <div className="submit">
  //                     <button
  //                       type="submit"
  //                       className="btn btn-primary btn-block"
  //                     >
  //                       {i18n.t("login")}
  //                     </button>
  //                   </div>

  //                   <div className="submit">
  //                     <Link to="/login_otp" className="text-primary ms-1">
  //                       {i18n.t("login_using_otp")}
  //                     </Link>
  //                   </div>

  //                   <p className="mb-2">
  //                     <Link to="/forgotPassword" className="text-info ms-1">
  //                       {i18n.t("forget_password")}
  //                     </Link>
  //                   </p>
  //                   <p className="text-dark mb-0">
  //                     {i18n.t("donthaveaccount")}
  //                     <Link to="/registration" className="text-dark ms-1">
  //                       {i18n.t("registration")}
  //                     </Link>
  //                   </p>
  //                 </form>
  //                 <SocialSignin />
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </section>
  //   </div>
  //   */}
  // );
  //}
}
